import { Box, Grid, Typography } from '@mui/material';
import {
  BooleanInput,
  Create,
  Datagrid,
  DateField,
  DeleteButton,
  Edit,
  EditButton,
  Filter,
  FormDataConsumer,
  RadioButtonGroupInput,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  SearchInput
} from 'react-admin';
import { List } from '../components';
import Permission from '../components/Permission';
import { PERMISSIONS } from '../constants';
import { getChainId } from '../utils/utils';
import { UserRole } from '../providers/authProvider';

const required = (message = 'Obrigatório') =>
  value => value ? undefined : message;

const choices = [
  { id: UserRole.chain, name: "Rede" },
  { id: UserRole.station, name: "Posto" }
];

const FilterChainEmployee = (props) => {
  return (
    <Filter {...props}>
      <SearchInput source="search" alwaysOn />
      <SelectInput
        label="Status"
        source="isInactive"
        emptyText="Todos"
        choices={[{ id: true, name: "Inativos" }, { id: false, name: "Ativos" }]}
        alwaysOn
      />
    </Filter>
  )
}

const ChainEmployeeList = (props) => {
  return (
    <List
      {...props}
      title="Usuários da Rede"
      filters={<FilterChainEmployee />}
      filter={getChainId() ? { chainId: getChainId(), role: UserRole.chain } : { role: UserRole.chain } }
      filterDefaultValues={{ isInactive: false }}
    >
      <Datagrid bulkActionButtons={false}>
        <TextField source="login" />
        <Permission permission={PERMISSIONS.ADMIN}>
          <ReferenceField label="Rede" source="chainId" reference="chains" allowEmpty>
            <TextField source="name" />
          </ReferenceField>
        </Permission>
        <DateField source="lastLogin" label="Último login" locales="pt-BR" emptyText="" showTime />
        <EditButton />
        <DeleteButton />
      </Datagrid>
    </List>
  );
}

const transform = data => ({
  ...data,
  stationId: (data.role === UserRole.chain ? null : data.stationId)
});

const ChainEmployeeEdit = (props) => {
  return (
    <Edit title='Editar usuário da rede' {...props} transform={transform}>
      <ChainEmployeeForm editing={true} />
    </Edit>
  );
};

const ChainEmployeeCreate = (props) => {
  return (
    <Create title='Adicionar usuário da rede' {...props} transform={transform}>
      <ChainEmployeeForm />
    </Create>
  );
};

const ChainEmployeeForm = ({ editing }) =>
  <SimpleForm defaultValues={{ chainId: getChainId() }}>
    <Grid container width={{ xs: '100%', xl: 800 }} spacing={2}>
      <Grid item xs={12} md={8}>
        <Box display={{ xs: 'block', sm: 'flex' }}>
          <Permission permission={PERMISSIONS.ADMIN}>
            <ReferenceInput source="chainId" reference="chains" sort={{ field: 'name', order: 'ASC' }}>
              <SelectInput sx={{ width: '100%' }} optionText="name" label="Rede" validate={required()} isRequired />
            </ReferenceInput>
          </Permission>
        </Box>
        <Box display={{ xs: 'block', sm: 'flex' }}>
          <Box flex={2} mr={{ xs: 0, sm: '0.5em' }}>
            <TextInput source="name" label="Nome" validate={required()} isRequired fullWidth />
          </Box>
          <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
            <SelectInput source="role" label="Tipo" choices={choices} validate={required()} isRequired fullWidth />
          </Box>
        </Box>
        <Box display={{ xs: 'block', sm: 'flex' }}>
          <Box flex={2} mr={{ xs: 0, sm: '0.5em' }}>
            <TextInput source="login" validate={required()} isRequired fullWidth />
          </Box>
          <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
            <TextInput source="password" label="Senha" type="password" defaultValue="" validate={editing ? null : required()} isRequired={!editing} fullWidth />
          </Box>
        </Box>
        <Box display={{ xs: 'block', sm: 'flex' }}>
          <Box flex={2} mr={{ xs: 0 }}>
            <FormDataConsumer>
              {({ formData, ...rest }) => {
                return formData.role === UserRole.station && (<ReferenceInput
                  source="stationId"
                  reference="stations"
                  sort={{ field: 'name', order: 'ASC' }}
                  perPage={null}
                  filter={{ chainId: getChainId() || formData.chainId }}
                >
                  <SelectInput label="Posto" optionText="name" validate={required()} isRequired fullWidth />
                </ReferenceInput>)
              }}
            </FormDataConsumer>
          </Box>
        </Box>

        <Typography variant="h6" gutterBottom>
          Perfil
        </Typography>
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            return (<ReferenceInput
              source="profileId"
              reference="profiles"
              sort={{ field: 'name', order: 'ASC' }}
              perPage={null}
              filter={{ chainId: getChainId() || formData.chainId }}
              validate={required()}
              isRequired >
              <RadioButtonGroupInput validate={required()} isRequired label="" row={false} optionText="name" />
            </ReferenceInput>)
          }}
        </FormDataConsumer>
        <BooleanInput source="mustChangePassword" label="Forçar usuário a trocar senha no primeiro acesso" />
        {
          editing &&
          <BooleanInput source="isInactive" label="Inativar usuário" />
        }
      </Grid>
    </Grid>
  </SimpleForm>;

const resource = {
  list: ChainEmployeeList,
  edit: ChainEmployeeEdit,
  create: ChainEmployeeCreate,
  name: 'chain-employees',
  permissions: [PERMISSIONS.LIST_USERS]
}
export default resource;