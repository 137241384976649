import { Box, Grid, Typography } from '@mui/material';
import {
  BooleanInput,
  Create,
  Datagrid,
  DateField,
  DeleteButton,
  Edit,
  EditButton,
  Filter,
  FormDataConsumer,
  RadioButtonGroupInput,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  SearchInput,
  TopToolbar,
  CreateButton,
  Toolbar,
  SaveButton
} from 'react-admin';
import { List } from '../components';
import Permission from '../components/Permission';
import { PERMISSIONS } from '../constants';
import { getChainId } from '../utils/utils';
import { UserRole } from '../providers/authProvider';

const required = (message = 'Obrigatório') =>
  value => value ? undefined : message;

const choices = [
  { id: UserRole.chain, name: "Rede" },
  { id: UserRole.station, name: "Posto" }
];

const CustomToolbar = () => {
  return (
    <Toolbar style={{
      flex: 1,
      display: 'flex',
      justifyContent: 'space-between'
    }}>
      <SaveButton />
      <DeleteButton redirect="/station-employees" />
    </Toolbar>
  )
}

const ListActions = props => {
  return (
    <TopToolbar>
      <CreateButton {...props} resource="station-employees" />
    </TopToolbar>
  );
};

const FilterChainEmployee = (props) => {
  return (
    <Filter {...props}>
      <SearchInput source="search" alwaysOn />
      <SelectInput
        label="Status"
        source="isInactive"
        emptyText="Todos"
        choices={[{ id: true, name: "Inativos" }, { id: false, name: "Ativos" }]}
        alwaysOn
      />
      <ReferenceInput
        source="stationId"
        reference="stations"
        sort={{ field: "name", order: "ASC" }}
        perPage={1000}
        alwaysOn
      >
        <SelectInput emptyText="Todos" label="Posto" optionText="name" />
      </ReferenceInput>
    </Filter>
  )
}

const StationEmployeeList = (props) => <List
  {...props}
  title="Usuários de Locais"
  resource="chain-employees"
  filters={<FilterChainEmployee />}
  filter={getChainId() ? { chainId: getChainId(), role: UserRole.station } : { role: UserRole.station }}
  filterDefaultValues={{ isInactive: false }}
  actions={<ListActions />}
>
  <Datagrid bulkActionButtons={false}>
    <TextField source="login" />
    <Permission label="Rede" permission={PERMISSIONS.ADMIN}>
      <ReferenceField label="Rede" source="chainId" reference="chains" allowEmpty>
        <TextField source="name" />
      </ReferenceField>
    </Permission>
    <ReferenceField
      label="Posto"
      reference="stations"
      source="stationId"
      textAlign="center"
      link={false}
    >
      <TextField source="name" />
    </ReferenceField>
    <DateField source="lastLogin" label="Último login" locales="pt-BR" emptyText="" showTime />
    <EditButton resource="station-employees" />
    <DeleteButton redirect="/station-employees" />
  </Datagrid>
</List>;

const transform = data => ({
  ...data,
  stationId: (data.role === UserRole.chain ? null : data.stationId)
});

const StationEmployeeEdit = (props) => {
  return (
    <Edit title='Editar usuário de locais' {...props} resource="chain-employees" transform={transform} redirect="/station-employees">
      <StationEmployeeForm editing={true} />
    </Edit>
  );
};

const StationEmployeeCreate = (props) => {
  return (
    <Create title='Adicionar usuário de locais' {...props} resource="chain-employees" transform={transform} redirect="/station-employees">
      <StationEmployeeForm />
    </Create>
  );
};

const StationEmployeeForm = ({ editing }) =>
  <SimpleForm defaultValues={{ chainId: getChainId(), role: UserRole.station }} toolbar={editing ? <CustomToolbar /> : <Toolbar />}>
    <Grid container width={{ xs: '100%', xl: 800 }} spacing={2}>
      <Grid item xs={12} md={8}>
        <Box display={{ xs: 'block', sm: 'flex' }}>
          <Permission permission={PERMISSIONS.ADMIN}>
            <ReferenceInput source="chainId" reference="chains" sort={{ field: 'name', order: 'ASC' }}>
              <SelectInput sx={{ width: '100%' }} optionText="name" label="Rede" validate={required()} isRequired />
            </ReferenceInput>
          </Permission>
        </Box>
        <Box display={{ xs: 'block', sm: 'flex' }}>
          <Box flex={2} mr={{ xs: 0, sm: '0.5em' }}>
            <TextInput source="name" label="Nome" validate={required()} isRequired fullWidth />
          </Box>
          <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
            <SelectInput source="role" label="Tipo" choices={choices} validate={required()} isRequired fullWidth />
          </Box>
        </Box>
        <Box display={{ xs: 'block', sm: 'flex' }}>
          <Box flex={2} mr={{ xs: 0, sm: '0.5em' }}>
            <TextInput source="login" validate={required()} isRequired fullWidth />
          </Box>
          <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
            <TextInput source="password" label="Senha" type="password" defaultValue="" validate={editing ? null : required()} isRequired={!editing} fullWidth />
          </Box>
        </Box>
        <Box display={{ xs: 'block', sm: 'flex' }}>
          <Box flex={2} mr={{ xs: 0 }}>
            <FormDataConsumer>
              {({ formData, ...rest }) => {
                return formData.role === UserRole.station && (<ReferenceInput
                  source="stationId"
                  reference="stations"
                  sort={{ field: 'name', order: 'ASC' }}
                  perPage={null}
                  filter={{ chainId: getChainId() || formData.chainId }}
                >
                  <SelectInput label="Posto" optionText="name" validate={required()} isRequired fullWidth />
                </ReferenceInput>)
              }}
            </FormDataConsumer>
          </Box>
        </Box>

        <Typography variant="h6" gutterBottom>
          Perfil
        </Typography>
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            return (<ReferenceInput
              source="profileId"
              reference="profiles"
              sort={{ field: 'name', order: 'ASC' }}
              perPage={null}
              filter={{ chainId: getChainId() || formData.chainId }}
              validate={required()}
              isRequired >
              <RadioButtonGroupInput validate={required()} isRequired label="" row={false} optionText="name" />
            </ReferenceInput>)
          }}
        </FormDataConsumer>
        <BooleanInput source="mustChangePassword" label="Forçar usuário a trocar senha no primeiro acesso" />
        {
          editing &&
          <BooleanInput source="isInactive" label="Inativar usuário" />
        }
      </Grid>
    </Grid>
  </SimpleForm>;

const resource = {
  list: StationEmployeeList,
  edit: StationEmployeeEdit,
  create: StationEmployeeCreate,
  name: 'station-employees',
  permissions: [PERMISSIONS.LIST_USERS]
}
export default resource;