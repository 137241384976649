import { LocationCity, LockOpen, SupervisedUserCircle } from '@mui/icons-material';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import AssessmentIcon from '@mui/icons-material/Assessment';
import AssignmentInd from '@mui/icons-material/AssignmentInd';
import DirectionsIcon from '@mui/icons-material/Directions';
import Vehicle from "@mui/icons-material/DirectionsCar";
import Domain from "@mui/icons-material/Domain";
import EmailIcon from '@mui/icons-material/Email';
import VehicleGroup from '@mui/icons-material/EmojiTransportation';
import VehicleBase from "@mui/icons-material/HomeWork";
import HttpsIcon from '@mui/icons-material/Https';
import InsertChart from "@mui/icons-material/InsertChart";
import LocalGasStation from "@mui/icons-material/LocalGasStation";
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import MonetizationOn from "@mui/icons-material/MonetizationOn";
import NotificationIcon from '@mui/icons-material/Notifications';
import Opacity from "@mui/icons-material/Opacity";
import People from "@mui/icons-material/People";
import Manager from '@mui/icons-material/PersonPinCircle';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import ReceiptIcon from '@mui/icons-material/Receipt';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import Redeem from '@mui/icons-material/Redeem';
import RoomServiceIcon from '@mui/icons-material/RoomService';
import SettingsIcon from '@mui/icons-material/Settings';
import InvoiceListIcon from '@mui/icons-material/TextSnippetSharp';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import WorkIcon from '@mui/icons-material/Work';
import { Badge, BadgeProps, styled } from '@mui/material';
import { useState } from 'react';
import { MenuItemLink, usePermissions } from 'react-admin';
import Permission from '../../components/Permission';
import Permissions from '../../components/Permissions';
import { PERMISSIONS } from '../../constants';
import { useStore } from '../../hooks/useStore';
import { getChainId, getCompanyId } from '../../utils/utils';
import SubMenu from './SubMenu';
import { UserRole } from '../../providers/authProvider';

const Menu = ({ dense }) => {
  const [state, setState] = useState({
    menuRegister: false,
    menuContact: false,
    menuReports: false,
    menuSecurity: false,
    menuMarketing: false,
    menuFinance: false,
  });

  const handleToggle = (menu) => {
    setState(state => ({ ...state, [menu]: !state[menu] }));
  };
  const { permissions } = usePermissions();
  const [unreadTotalChatMessage] = useStore('unreadTotalChatMessage');

  const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
    '& .MuiBadge-badge': {
      right: -3,
      top: 13,
      border: `2px solid ${theme.palette.background.paper}`,
      padding: '0 4px',
    },
  }));

  return (
    <div>
      <SubMenu
        handleToggle={() => handleToggle('menuRegister')}
        isOpen={state.menuRegister}
        icon={<AppRegistrationIcon />}
        name="Listas"
        dense={dense}
      >
        <Permissions permissions={[PERMISSIONS.LIST_FILLINS, PERMISSIONS.APPROVE_FILLINS]}>
          <MenuItemLink
            to="/fillins"
            primaryText="Abastecimentos"
            leftIcon={<LocalGasStation />}
          />
        </Permissions>
        <Permissions permissions={[PERMISSIONS.LIST_COST_CENTERS]}>
          <MenuItemLink
            to="/cost-centers"
            primaryText="Centros de Custos"
            leftIcon={<MonetizationOn />}
          />
        </Permissions>
        <Permissions permissions={[PERMISSIONS.ADMIN]}>
          <MenuItemLink
            to="/cities"
            primaryText="Cidades"
            leftIcon={<LocationCity />}
          />
        </Permissions>
        <Permissions permissions={[PERMISSIONS.ADMIN]}>
          <MenuItemLink
            to="/chains"
            primaryText="Redes de Postos"
            leftIcon={<Domain />}
          />
        </Permissions>
        <Permissions permissions={[PERMISSIONS.ADMIN]}>
          <MenuItemLink
            to="/companies"
            primaryText="Grupo de transportadoras"
            leftIcon={<Domain />}
          />
        </Permissions>
        <Permissions permissions={[PERMISSIONS.LIST_SUB_COMPANIES]} userRoles={[UserRole.admin, UserRole.company]}>
          <MenuItemLink
            to="/sub-companies"
            primaryText="Transportadoras"
            leftIcon={<Domain />}
          />
        </Permissions>
        <Permissions permissions={[PERMISSIONS.LIST_USERS]} userRoles={[UserRole.admin, UserRole.company]}>
          <MenuItemLink
            to="/company-employees"
            primaryText={permissions.includes(PERMISSIONS.ADMIN) ? "Usuário (G.Transportadora)" : "Usuários do Sistema"}
            leftIcon={<People />}
          />
        </Permissions>
        <Permissions permissions={[PERMISSIONS.LIST_USERS]} userRoles={[UserRole.admin, UserRole.chain]}>
          <MenuItemLink
            to="/chain-employees"
            primaryText={permissions.includes(PERMISSIONS.ADMIN) ? "Usuário (Rede)" : "Usuários do Sistema"}
            leftIcon={<People />}
          />
        </Permissions>
        <Permissions permissions={[PERMISSIONS.LIST_USERS]} userRoles={[UserRole.admin, UserRole.chain]}>
          <MenuItemLink
            to="/station-employees"
            primaryText={"Usuário locais"}
            leftIcon={<People />}
          />
        </Permissions>
        <Permissions permissions={[PERMISSIONS.LIST_MANAGERS]}>
          <MenuItemLink
            to="/managers"
            primaryText="Gestores"
            leftIcon={<Manager />}
          />
        </Permissions>
        <Permissions permissions={[PERMISSIONS.LIST_VEHICLES]}>
          <MenuItemLink
            to="/vehicles"
            primaryText="Veículos"
            leftIcon={<Vehicle />}
          />
        </Permissions>
        <Permissions permissions={[PERMISSIONS.LIST_VEHICLE_GROUPS]}>
          <MenuItemLink
            to="/vehicle-groups"
            primaryText="Grupos de Veículos"
            leftIcon={<VehicleGroup />}
          />
        </Permissions>
        <Permissions permissions={[PERMISSIONS.LIST_VEHICLE_BASES]}>
          <MenuItemLink
            to="/vehicle-bases"
            primaryText="Filiais"
            leftIcon={<VehicleBase />}
          />
        </Permissions>
        <Permissions permissions={[PERMISSIONS.LIST_VALETS]}>
          <MenuItemLink
            to="/valets"
            primaryText="Manobristas"
            leftIcon={<AssignmentInd />}
          />
        </Permissions>
        <Permissions permissions={[PERMISSIONS.LIST_DRIVERS]}>
          <MenuItemLink
            to="/drivers"
            primaryText="Motoristas"
            leftIcon={<AssignmentInd />}
          />
        </Permissions>
        <Permissions permissions={[PERMISSIONS.LIST_AGGREGATED_DRIVES]}>
          <MenuItemLink
            to="/aggregated-drivers"
            primaryText="Motoristas Agregados"
            leftIcon={<AssignmentInd />}
          />
        </Permissions>
        <Permissions permissions={[PERMISSIONS.LIST_OPERATIONS]}>
          <MenuItemLink
            to="/operations"
            primaryText="Operações"
            leftIcon={<VehicleBase />}
          />
        </Permissions>
        <Permissions permissions={[PERMISSIONS.LIST_ROUTES]}>
          <MenuItemLink
            to="/routes"
            primaryText="Rotas"
            leftIcon={<DirectionsIcon />}
          />
        </Permissions>
        <Permissions permissions={[PERMISSIONS.LIST_VOUCHERS]}>
          <MenuItemLink
            to="/vouchers"
            primaryText="Vouchers"
            leftIcon={<Redeem />}
          />
        </Permissions>
        <Permissions permissions={[PERMISSIONS.LIST_STATIONS]}>
          <MenuItemLink
            to="/stations"
            primaryText="Postos"
            leftIcon={<LocalGasStation />}
          />
        </Permissions>
        <Permissions permissions={[PERMISSIONS.LIST_ATTENDANTS]}>
          <MenuItemLink
            to="/station-attendants"
            primaryText="Frentistas"
            leftIcon={<People />} />
        </Permissions>
        <Permissions permissions={[PERMISSIONS.ADMIN]}>
          <MenuItemLink
            to="/parent-fuels"
            primaryText="Combustíveis"
            leftIcon={<Opacity />}
          />
        </Permissions>
        <Permissions permissions={[PERMISSIONS.ADMIN]}>
          <MenuItemLink
            to="/services"
            primaryText="Serviços"
            leftIcon={<RoomServiceIcon />}
          />
        </Permissions>
        <Permissions permissions={[PERMISSIONS.LIST_OTP_CODES]}>
          <MenuItemLink
            to="/otp-code"
            primaryText="Códigos OTP"
            leftIcon={<HttpsIcon />}
          />
        </Permissions>
        <Permissions permissions={[PERMISSIONS.LIST_FILLIN_ATTEMPTS]}>
          <MenuItemLink
            to="/fillin-attempts"
            primaryText="Tentativas de abast."
            leftIcon={<HttpsIcon />}
          />
        </Permissions>
        <Permission permission={PERMISSIONS.ADMIN}>
          <MenuItemLink
            to="/permission-groups"
            primaryText="Grupos de Permissões"
            leftIcon={<LockOpen />}
          />
        </Permission>
        <Permission permission={PERMISSIONS.ADMIN}>
          <MenuItemLink
            to="/user-permissions"
            primaryText="Permissões"
            leftIcon={<LockOpen />}
          />
        </Permission>
        <Permission permission={PERMISSIONS.LIST_PROFILES}>
          <MenuItemLink
            to="/profiles"
            primaryText="Perfis de Acesso"
            leftIcon={<SupervisedUserCircle />}
          />
        </Permission>
        <Permission permission={PERMISSIONS.MAILING_LIST}>
          <MenuItemLink
            to="/mailing-list"
            primaryText="Lista de Email"
            leftIcon={<EmailIcon />}
          />
        </Permission>
      </SubMenu>
      {/* </Permissions> */}
      <Permissions permissions={[PERMISSIONS.FILL_IN_VEHICLE]}>
        <MenuItemLink
          to="/fillin-process"
          primaryText="Abastecer agora"
          leftIcon={<LocalGasStation />}
        />
      </Permissions>
      <Permissions permissions={[PERMISSIONS.LIST_CONTRACTS]}>
        <MenuItemLink
          to="/contracts"
          primaryText="Contratos"
          leftIcon={<WorkIcon />}
        />
      </Permissions>
      <Permissions permissions={[PERMISSIONS.LIST_CHATS]}>
        <MenuItemLink
          to="/chats"
          primaryText="Chat"
          leftIcon={
            <StyledBadge badgeContent={unreadTotalChatMessage || 0} color="primary">
              <QuestionAnswerIcon />
            </StyledBadge>
          }

        />
      </Permissions>
      <Permission permission={PERMISSIONS.LIST_COMPANIES} userRole={UserRole.chain}>
        <MenuItemLink
          to="/linked-companies"
          primaryText="Transportadora"
          leftIcon={<LocalShippingIcon />}
        />
      </Permission>
      <Permission permission={PERMISSIONS.LIST_CHAINS} userRole={UserRole.company}>
        <MenuItemLink
          to="/linked-chains"
          primaryText="Redes"
          leftIcon={<Domain />}
        />
      </Permission>
      <Permissions permissions={[PERMISSIONS.LIST_NOTIFICATIONS]}>
        <MenuItemLink
          to="/company/notification"
          primaryText="Notificações Push"
          leftIcon={<NotificationIcon />}
        />
      </Permissions>
      <Permission permission={PERMISSIONS.COMPANY_CONFIG}>
        <MenuItemLink
          to="/company-config"
          primaryText="Configurações"
          leftIcon={<SettingsIcon />}
        />
      </Permission>
      <Permissions permissions={[PERMISSIONS.CREATE_INVOICE]}>
        <MenuItemLink
          to="/import-invoice"
          primaryText="Importar XML"
          leftIcon={<UploadFileIcon />}
        />
      </Permissions>
      <Permissions permissions={[PERMISSIONS.CREATE_INVOICE, PERMISSIONS.LIST_INVOICE]}>
        <SubMenu
          handleToggle={() => handleToggle('menuFinance')}
          isOpen={state.menuFinance}
          icon={<ReceiptLongIcon />}
          name="Financeiro"
          dense={dense}
        >
          <Permissions permissions={[PERMISSIONS.CREATE_INVOICE]}>
            <MenuItemLink
              to="/bills"
              primaryText="Faturas"
              leftIcon={<InvoiceListIcon />}
            />
          </Permissions>
          <Permissions permissions={[PERMISSIONS.LIST_INVOICE]}>
            <MenuItemLink
              to="/invoices"
              primaryText="Notas Fiscais"
              leftIcon={<ReceiptIcon />}
            />
          </Permissions>
        </SubMenu>
      </Permissions>
      <Permissions permissions={[
        PERMISSIONS.REPORT_FILLINS,
        PERMISSIONS.REPORT_FINANCIAL,
        PERMISSIONS.REPORT_BLOCKED_DRIVERS,
        PERMISSIONS.REPORT_TRANSACTIONS,
        PERMISSIONS.REPORT_AVERAGE,
        PERMISSIONS.REPORT_AVERAGE_BY_DRIVER,
        PERMISSIONS.REPORT_AVERAGE_BY_VEHICLE,
        PERMISSIONS.REPORT_BILLS,
        PERMISSIONS.REPORT_PRICES,
        PERMISSIONS.REPORT_BEST_PRICES,
      ]}>
        <SubMenu
          handleToggle={() => handleToggle('menuReports')}
          isOpen={state.menuReports}
          icon={<AssessmentIcon />}
          name="Relatórios"
          dense={dense}
        >
          <Permissions permissions={[PERMISSIONS.REPORT_FILLINS]}>
            <MenuItemLink
              to="/fillin-report"
              primaryText="Abastecimentos"
              leftIcon={<InsertChart />}
            />
          </Permissions>
          <Permissions permissions={[PERMISSIONS.REPORT_FILLINS]}>
            <MenuItemLink
              to="/detailed-fillins-report"
              primaryText="Abast. Detalhados"
              leftIcon={<InsertChart />}
            />
          </Permissions>
          <Permissions permissions={[PERMISSIONS.REPORT_PRICE_CHANGES]}>
            <MenuItemLink
              to="/price-changes-report"
              primaryText="Alterações de Preço"
              leftIcon={<InsertChart />}
            />
          </Permissions>
          <Permissions permissions={[PERMISSIONS.REPORT_BILLS]}>
            <MenuItemLink
              to="/bill-report"
              primaryText="Faturas"
              leftIcon={<InsertChart />}
            />
          </Permissions>
          <Permissions permissions={[PERMISSIONS.REPORT_FINANCIAL]}>
            <MenuItemLink
              to="/financial-report"
              primaryText="Financeiro"
              leftIcon={<InsertChart />}
            />
          </Permissions>
          <Permissions permissions={[PERMISSIONS.REPORT_BLOCKED_DRIVERS]}>
            <MenuItemLink
              to="/blocked-drivers-report"
              primaryText="Motoristas Bloqueados"
              leftIcon={<InsertChart />}
            />
          </Permissions>
          <Permissions permissions={[PERMISSIONS.REPORT_TRANSACTIONS]}>
            <MenuItemLink
              to="/transaction-report"
              primaryText="Transações"
              leftIcon={<InsertChart />}
            />
          </Permissions>
          <Permissions permissions={[PERMISSIONS.REPORT_AVERAGE]}>
            <MenuItemLink
              to="/average-report"
              primaryText="Médias"
              leftIcon={<InsertChart />}
            />
          </Permissions>
          <Permissions permissions={[PERMISSIONS.REPORT_AVERAGE_BY_DRIVER]}>
            <MenuItemLink
              to="/driver-average-report"
              primaryText="Médias por Motorista"
              leftIcon={<InsertChart />}
            />
          </Permissions>
          <Permissions permissions={[PERMISSIONS.REPORT_AVERAGE_BY_VEHICLE]}>
            <MenuItemLink
              to="/vehicle-average-report"
              primaryText="Médias por Veículo"
              leftIcon={<InsertChart />}
            />
          </Permissions>
          <Permissions permissions={[PERMISSIONS.REPORT_BEST_PRICES]}>
            <MenuItemLink
              to="/best-prices-report"
              primaryText="Melhor Preço"
              leftIcon={<InsertChart />}
            />
          </Permissions>
          <Permissions permissions={[PERMISSIONS.REPORT_PRICES]}>
            <MenuItemLink
              to="/prices-report"
              primaryText="Preços"
              leftIcon={<InsertChart />}
            />
          </Permissions>
          <Permissions permissions={[PERMISSIONS.REPORT_DETAILED_PRICE]}>
            <MenuItemLink
              to="/detailed-price-report"
              primaryText="Preço detalhado"
              leftIcon={<InsertChart />}
            />
          </Permissions>
        </SubMenu>
      </Permissions>
    </div>
  );
};

export default Menu;
