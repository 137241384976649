import MapIcon from '@mui/icons-material/Map';
import ContentAdd from '@mui/icons-material/Add';
import { makeStyles } from "@mui/styles";
import { Grid } from '@mui/material';
import React, { Fragment, useEffect, useState } from "react";
import {
  ArrayField,
  ArrayInput,
  BooleanInput,
  Button,
  Create,
  Datagrid,
  DateField,
  DeleteButton,
  Edit,
  EditButton,
  ExportButton,
  Filter,
  FormDataConsumer,
  FormTab,
  FunctionField,
  ImageField,
  ImageInput,
  Labeled,
  NumberField,
  NumberInput,
  ReferenceArrayInput,
  ReferenceField,
  ReferenceInput,
  SelectArrayInput,
  SelectInput,
  Show,
  SimpleFormIterator,
  Tab,
  TabbedForm,
  TabbedShowLayout,
  TextField,
  TextInput,
  TimeInput,
  TopToolbar,
  email,
  minLength,
  minValue,
  useDataProvider,
  useGetIdentity,
  useNotify,
  usePermissions,
  useRecordContext,
  useRedirect,
  useRefresh,
  useResourceContext,
  useShowContext
} from "react-admin";
import { LazyLoadAutoCompleteInput, List, Permission, Permissions } from "../components";
import { PERMISSIONS } from "../constants";
import { BankAccountType, BankAccountTypeChoices } from "../models";
import { UserRole } from '../providers/authProvider';
import BankService from "../services/bankService";
import { CEPInput, CNPJInput, PhoneInput, StateInput } from "../utils/Inputs";
import { formatCellphone, formatCnpj, getChainId, getCompanyId } from "../utils/utils";
import ComponentWithPermissions from "./ComponentWithPermissions";

const weekDays = ['Domingo', 'Segunda-Feira', 'Terça-Feira', 'Quarta-Feira', 'Quinta-Feira', 'Sexta-Feira', 'Sábado'].map((d, i) => ({ id: i, name: d }));

const useStyles = makeStyles(theme => ({
  title: {
    margin: '20px 10px 0px 0px',
    '& span': {
      fontSize: 16
    },
    image: {
      '& img': {
        maxWidth: '4rem',
      },
    },
  },
  credentialField: {
    display: 'block',

  },
  inputWrapperStyle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: '0 0 20px 0',
  },
  input: {
    margin: '0 20px 0 0',
  },
  day: {
    fontSize: 16,
    margin: '20px 0'
  }
}));

const required = (message = 'Obrigatório') =>
  value => value ? undefined : message;

const StationFilter = props => {
  const { identity } = useGetIdentity();
  return (
    <Filter {...props}>
      <TextInput label="Pesquisar" source="search" alwaysOn />
      {identity?.role === UserRole.admin &&
        <ReferenceInput source="chainId" reference="chains" alwaysOn>
          <SelectInput label="Rede" resettable={true} optionText="name" />
        </ReferenceInput>}
      {identity?.role === UserRole.company &&
        <SelectInput
          label="Status"
          source="isInactive"
          emptyText="Todos"
          choices={[
            { id: false, name: 'Ativos' },
            { id: true, name: 'Inativos' }
          ]}
          sx={{ marginBottom: 0.5 }}
          allowEmpty
          alwaysOn
        />
      }
    </Filter>
  );
};

const ButtonInactiveStation = (props) => {
  const record = useRecordContext(props);
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();

  const isInactive = record.companyStations.some((cps) => cps?.isInactive === true);

  const doUpdate = () => {
    dataProvider.update('companies/stations', {
      id: record.id,
      data: { isInactive: !isInactive },
      previousData: record.companyStations
    })
      .then(() => {
        notify(`Posto ${isInactive ? 'Ativado' : 'Desativado'} com sucesso`);
        refresh();
      })
      .catch(() => { notify(`Falha ao ${isInactive ? 'Ativar' : 'Desativar'} Posto`, { type: "warning" }); });
  };
  return (
    <Button
      label={isInactive ? "Ativar" : "Desativar"}
      variant="contained"
      onClick={(e) => {
        e.stopPropagation();
        doUpdate();
      }}
    />
  );
};

const ListActions = () => {
  const redirect = useRedirect();
  const resource = useResourceContext();

  return (
    <TopToolbar>
      <Permissions userRoles={[UserRole.admin]}>
        <Button
          onClick={(e) => {
            e.stopPropagation();
            redirect("create", resource);
          }}
          label="Criar"
        >
          <ContentAdd />
        </Button>
      </Permissions>
      <ExportButton />
      <Permissions userRoles={[UserRole.company]}>
        <Button
          onClick={() => redirect('/map-stations')}
          label="Mostrar mapa"
        >
          <MapIcon />
        </Button>
      </Permissions>
    </TopToolbar>
  );
};

const StationList = props => {
  const { identity } = useGetIdentity();
  const classes = useStyles();

  let filterValue;
  switch (identity?.role) {
    case UserRole.chain:
      filterValue = { chainId: getChainId() };
      break;
    case UserRole.company:
      filterValue = { companyId: getCompanyId() };
      break;
    default:
      filterValue = {};
      break;
  }

  return (
    <List {...props}
      title="Postos"
      filter={filterValue}
      filters={<StationFilter />}
      actions={<ListActions />}
    >
      <Datagrid
        rowClick="show"
        bulkActionButtons={false}
      >
        {
          identity && [UserRole.admin, UserRole.chain].includes(identity.role) &&
          <ImageField className={classes.image} source="logoPath" label="Logo" />
        }
        <TextField source="name" label="Nome" />
        {
          identity?.role === UserRole.company &&
          <ReferenceField
            label="Rede"
            source="chainId"
            emptyText="Não identificada"
            reference="chains"
            link={false}
          >
            <TextField source="name" />
          </ReferenceField>
        }
        {
          identity && [UserRole.admin, UserRole.chain].includes(identity.role) &&
          <NumberField source="numericId" label="ID Numérico" />
        }
        <FunctionField render={record => formatCnpj(record.cnpj)} label="CNPJ" />
        {
          identity && [UserRole.admin, UserRole.chain].includes(identity.role) &&
          <FunctionField render={record => formatCellphone(record.phone)} label="Telefone" sortable={false} />
        }
        {
          identity && [UserRole.admin, UserRole.chain].includes(identity.role) &&
          <DateField source="createdAt" label="Adicionado em" locales="pt-BR" />
        }
        {
          identity && [UserRole.admin, UserRole.chain].includes(identity.role) &&
          <EditButton />
        }
        {
          identity && [UserRole.admin, UserRole.chain].includes(identity.role) &&
          <DeleteButton />
        }
        {
          identity?.role === UserRole.company &&
          <ButtonInactiveStation />
        }
      </Datagrid>
    </List >
  );
};

const transformEdit = (data) => ({
  ...data,
  contacts: data?.contacts.map(({ chainId, companyId, ...rest }) => ({ ...rest }))
})

const StationEdit = props => {
  const { permissions } = usePermissions();
  const classes = useStyles();

  const [banks, setBanks] = useState([]);

  useEffect(() => {
    const bankService = new BankService();
    bankService.getBanks().then(({ data }) => {
      setBanks(data.sort((a, b) => a.code - b.code));
    });
  }, []);

  return (
    <Edit title='Editar posto' {...props} transform={transformEdit}>
      <TabbedForm>
        <FormTab label="Identificação">
          <TextInput disabled label="ID" source="id" />
          <Permission permission={PERMISSIONS.ADMIN}>
            <NumberInput source="numericId" label="ID Numérico" />
          </Permission>
          <TextInput source="name" label="Nome" />
          <TextInput label="Email" source="email" type="email" validate={email()} />
          <CNPJInput source="cnpj" label="CNPJ" />
          <TextInput source="code" label="Código" />
          <PhoneInput source="phone" label="Telefone" />
          {
            permissions && permissions.includes(PERMISSIONS.ADMIN) &&
            <ReferenceInput source='chainId' reference="chains" validate={required()}>
              <SelectInput label="Rede" optionText="name" />
            </ReferenceInput>
          }
          <ReferenceArrayInput
            sort={{ field: 'name', order: 'ASC' }}
            perPage={1000}
            source="fuelIds"
            reference="fuels"
          >
            <SelectArrayInput optionText="name" label="Combustíveis" sx={{ marginBottom: 0.8, paddingTop: 1 }} />
          </ReferenceArrayInput>
          <ReferenceArrayInput
            sort={{ field: 'name', order: 'ASC' }}
            perPage={1000}
            source="serviceIds"
            reference="services"
          >
            <SelectArrayInput optionText="name" label="Serviços" sx={{ marginBottom: 0.8, paddingTop: 1 }} />
          </ReferenceArrayInput>
          <BooleanInput source="mustIssueInvoice" label="Exigir emissão de nota fiscal eletrônica" />
          <BooleanInput source="isInternal" label="Posto Interno" />
          <ImageInput source="logofile" label="Logo" accept="image/*">
            <ImageField source="src" label="Imagem" />
          </ImageInput>
          <ImageInput source="coverimagefile" label="Imagem retangular" accept="image/*">
            <ImageField source="src" label="Imagem" />
          </ImageInput>
        </FormTab>
        <FormTab label="Endereço">
          <TextInput source="street" label="Rua" />
          <TextInput source="streetNumber" label="Número" />
          <TextInput source="neighbourhood" label="Bairro" />
          <TextInput source="city" label="Cidade" />
          <StateInput source="state" label="Estado" />
          <CEPInput source="cep" label="CEP" />
          <NumberInput source="latitude" label="Latitude" />
          <NumberInput source="longitude" label="Longitude" />
        </FormTab>
        <FormTab label='GPS'>
          <Labeled label="Informe as coordenadas em 4 pontos que formam um retângulo" className={classes.title}>
            <FormDataConsumer>
              {({ formData: { coordinates } }) =>
                <ArrayInput source='coordinates' label=''>
                  <SimpleFormIterator inline disableReordering disableAdd={coordinates && coordinates.length === 4}>
                    <NumberInput source="latitude" label="Latitude" />
                    <NumberInput source="longitude" label="Longitude" />
                  </SimpleFormIterator>
                </ArrayInput>
              }
            </FormDataConsumer>
          </Labeled>
        </FormTab>
        <FormTab label="Horários">
          <OpeningHoursInput />
        </FormTab>
        <FormTab label="Dados bancários">
          <TextInput source="accountName" label="Nome Completo" />
          <CNPJInput source="accountCnpj" label="CNPJ" />
          <FormDataConsumer>
            {({ formData }) => (
              banks.length ?
                <LazyLoadAutoCompleteInput
                  label="Banco"
                  source="bankCode"
                  format={choice => `${choice.code} - ${choice.name}`}
                  style={{ minWidth: 250, marginTop: 8 }}
                  defaultChoices={banks}
                  record={formData}
                />
                :
                <></>
            )}
          </FormDataConsumer>
          <TextInput source="agency" label="Agência" parse={val => val ? val.toUpperCase() : null} />
          <TextInput source="accountNumber" label="Conta" parse={val => val ? val.toUpperCase() : null} />
          <SelectInput
            choices={BankAccountTypeChoices}
            source="accountType"
            label="Tipo da conta"
            optionText="label"
            optionValue="value"
          />
        </FormTab>
        <FormTab label="Contatos">
          <ArrayInput source="contacts" label="">
            <SimpleFormIterator fullWidth disableReordering>
              <FormDataConsumer>
                {({ getSource }) => (
                  <>
                    <Grid container lg={8} md={12} spacing={2}>
                      <Grid item xs={12} md={4}>
                        <TextInput source={getSource("name")} label="Nome" fullWidth />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <TextInput source={getSource("position")} label="Função" fullWidth />
                      </Grid>
                    </Grid>
                    <Grid container lg={8} md={12} spacing={2}>
                      <Grid item sx={12} md={4}>
                        <PhoneInput source={getSource("phone")} label="Telefone" fullWidth />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <TextInput source={getSource("email")} type="email" label="Email" validate={email()} fullWidth />
                      </Grid>
                    </Grid>
                  </>
                )}
              </FormDataConsumer>
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
        <Permission permission={PERMISSIONS.ADMIN} userRole={UserRole.admin}>
          <FormTab label="Mapa de Pista">
            <ArrayInput source="qrCodes" label="">
              <SimpleFormIterator fullWidth disableReordering disableClear>
                <TextInput source="identifier" label="Identificador" disabled />
                <ArrayInput source="hoses" label="">
                  <SimpleFormIterator fullWidth disableReordering inline>
                    <NumberInput source="code" label="Código" validate={[minValue(1), required()]} />
                    <ReferenceInput
                      reference="fuels"
                      source="fuelId"
                      sort={{ field: "name", order: "ASC" }}
                    >
                      <SelectInput label="Combustível" optionText="name" validate={required()} isRequired sx={{ minWidth: 200 }} />
                    </ReferenceInput>
                  </SimpleFormIterator>
                </ArrayInput>
              </SimpleFormIterator>
            </ArrayInput>
          </FormTab>
        </Permission>
      </TabbedForm>
    </Edit>
  )
};

const OpeningHoursInput = (props) => {
  const classes = useStyles();

  return (
    <ArrayInput source="openingHours" label="">
      <SimpleFormIterator disableAdd disableRemove disableReordering>
        <FormDataConsumer>
          {({ scopedFormData, getSource }) => {
            return (
              <p className={classes.day}>{scopedFormData && getSource && scopedFormData && weekDays.find(wd => wd.id === scopedFormData.dayOfTheWeek).name}</p>
            );
          }}
        </FormDataConsumer>
        <FormDataConsumer>
          {({ scopedFormData, getSource }) => {
            return (
              <div className={classes.inputWrapperStyle}>
                <BooleanInput
                  source={getSource && getSource("closed")}
                  label='Fechado'
                  className={classes.input}
                />
                {scopedFormData && !scopedFormData.closed &&
                  <BooleanInput
                    source={getSource && getSource("open24hrs")}
                    label='Aberto 24 horas'
                    className={classes.input}
                  />
                }
                {scopedFormData && !scopedFormData.closed && !scopedFormData.open24hrs &&
                  <Fragment>
                    <TimeInput
                      record={scopedFormData}
                      variant='filled'
                      label='Abre às'
                      source={getSource && getSource("startTime")}
                      style={{ margin: '0 20px 0 0', width: 120 }}
                      fullDate
                    />
                    <TimeInput
                      record={scopedFormData}
                      variant='filled'
                      label='Fecha às'
                      style={{ width: 120 }}
                      source={getSource && getSource("endTime")}
                      fullDate
                    />
                  </Fragment>
                }
              </div>
            );
          }}
        </FormDataConsumer>
      </SimpleFormIterator>
    </ArrayInput>
  )
};

const createStationEmailValidate = [email(), required()];
const createStationPhoneValidate = [minLength(10), required()];

const StationCreate = props => {
  const { permissions } = usePermissions();
  const classes = useStyles();

  const [banks, setBanks] = useState([]);

  useEffect(() => {
    const bankService = new BankService();
    bankService.getBanks().then(({ data }) => {
      setBanks(data.sort((a, b) => a.code - b.code));
    });
  }, []);

  return (
    <Create
      title='Novo Posto'
      transform={data => {
        Object.keys(data).forEach(key => {
          if (data[key] === undefined) {
            delete data[key];
          }
        });

        return data;
      }}
      {...props}
    >
      <TabbedForm sanitizeEmptyValues defaultValue={permissions && !permissions.includes(PERMISSIONS.ADMIN) && { chainId: getChainId() }}>
        <FormTab label="Identificação">
          <NumberInput source="numericId" label="ID Numérico" />
          <TextInput source="name" label="Nome" validate={required()} />
          <TextInput source="email" label="Email" type="email" validate={createStationEmailValidate} />
          <FormDataConsumer>
            {({ formData }) => (
              <CNPJInput source="cnpj" label="CNPJ" isRequired={!formData.isInternal} />
            )}
          </FormDataConsumer>
          <TextInput source="code" label="Código" />
          <PhoneInput source="phone" label="Telefone" validate={createStationPhoneValidate} />
          <Permission permission={PERMISSIONS.ADMIN}>
            <ReferenceInput source='chainId' reference="chains" validate={required()}>
              <SelectInput label="Rede" optionText="name" />
            </ReferenceInput>
          </Permission>
          <BooleanInput source="mustIssueInvoice" label="Exigir emissão de nota fiscal eletrônica" />
          <BooleanInput source="isInternal" label="Posto Interno" />
          <ReferenceArrayInput
            sort={{ field: 'name', order: 'ASC' }}
            perPage={1000}
            source="fuelIds"
            reference="fuels"
          >
            <SelectArrayInput optionText="name" label="Combustíveis" sx={{ marginBottom: 0.8, paddingTop: 1 }} />
          </ReferenceArrayInput>
          <ReferenceArrayInput
            sort={{ field: 'name', order: 'ASC' }}
            perPage={1000}
            source="serviceIds"
            reference="services"
          >
            <SelectArrayInput defaultValue={[]} optionText="name" label="Serviços" sx={{ marginBottom: 0.8, paddingTop: 1 }} />
          </ReferenceArrayInput>
          <ImageInput source="logofile" label="Logo" accept="image/*">
            <ImageField source="src" label="Imagem" />
          </ImageInput>
          <ImageInput source="coverimagefile" label="Imagem retangular" accept="image/*">
            <ImageField source="src" label="Imagem" />
          </ImageInput>
        </FormTab>
        <FormTab label="Endereço">
          <TextInput source="street" label="Rua" validate={required()} />
          <TextInput source="streetNumber" label="Número" validate={required()} />
          <TextInput source="neighbourhood" label="Bairro" validate={required()} />
          <TextInput source="city" label="Cidade" validate={required()} />
          <StateInput source="state" label="Estado" validate={required()} />
          <CEPInput source="cep" label="CEP" isRequired />
          <NumberInput source="latitude" label="Latitude" validate={required()} />
          <NumberInput source="longitude" label="Longitude" validate={required()} />
        </FormTab>
        <FormTab label='GPS'>
          <Labeled fullWidth label="Informe as coordenadas em 4 pontos que formam um retângulo" className={classes.title}>
            <FormDataConsumer>
              {({ formData: { coordinates } }) =>
                <ArrayInput source='coordinates' label=''>
                  <SimpleFormIterator inline disableReordering disableAdd={coordinates && coordinates.length === 4}>
                    <NumberInput source="latitude" label="Latitude" />
                    <NumberInput source="longitude" label="Longitude" />
                  </SimpleFormIterator>
                </ArrayInput>
              }
            </FormDataConsumer>
          </Labeled>
        </FormTab>
        <FormTab label="Horários">
          <ArrayInput source="openingHours" label="" defaultValue={weekDays.map(wd => ({ closed: true, dayOfTheWeek: wd.id }))}>
            <SimpleFormIterator disableAdd disableRemove disableReordering>
              <FormDataConsumer>
                {({ scopedFormData, getSource }) => {
                  return (
                    <p className={classes.day}>{scopedFormData && getSource && scopedFormData && weekDays.find(wd => wd.id === scopedFormData.dayOfTheWeek).name}</p>
                  );
                }}
              </FormDataConsumer>
              <FormDataConsumer>
                {({ scopedFormData, getSource }) => {
                  return (
                    <div className={classes.inputWrapperStyle}>
                      <BooleanInput
                        source={getSource && getSource("closed")}
                        label='Fechado'
                        className={classes.input}
                      />
                      {scopedFormData && !scopedFormData.closed &&
                        <BooleanInput
                          source={getSource && getSource("open24hrs")}
                          label='Aberto 24 horas'
                          className={classes.input}
                        />
                      }
                      {scopedFormData && !scopedFormData.closed && !scopedFormData.open24hrs &&
                        <Fragment>
                          <TimeInput
                            record={scopedFormData}
                            variant='filled'
                            label='Abre às'
                            source={getSource && getSource("startTime")}
                            style={{ margin: '0 20px 0 0', width: 120 }}
                            fullDate
                          />
                          <TimeInput
                            record={scopedFormData}
                            variant='filled'
                            label='Fecha às'
                            style={{ width: 120 }}
                            source={getSource && getSource("endTime")}
                            fullDate
                          />
                        </Fragment>
                      }
                    </div>
                  );
                }}
              </FormDataConsumer>
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
        <FormTab label="Dados bancários">
          <TextInput source="accountName" label="Nome Completo" />
          <CNPJInput source="accountCnpj" label="CNPJ" />
          <FormDataConsumer>
            {({ formData }) => (
              banks.length ?
                <LazyLoadAutoCompleteInput
                  label="Banco"
                  source="bankCode"
                  format={choice => `${choice.code} - ${choice.name}`}
                  style={{ minWidth: 250, marginTop: 8 }}
                  defaultChoices={banks}
                  record={formData}
                />
                :
                <></>
            )}
          </FormDataConsumer>
          <TextInput source="agency" label="Agência" parse={val => val.toUpperCase()} />
          <TextInput source="accountNumber" label="Conta" parse={val => val.toUpperCase()} />
          <SelectInput
            choices={BankAccountTypeChoices}
            source="accountType"
            label="Tipo da conta"
            optionText="label"
            optionValue="value"
          />
        </FormTab>
        <FormTab label="Contatos">
          <ArrayInput source="contacts" label="">
            <SimpleFormIterator fullWidth disableReordering>
              <FormDataConsumer>
                {({ getSource }) => (
                  <>
                    <Grid container lg={8} md={12} spacing={2}>
                      <Grid item xs={12} md={4}>
                        <TextInput source={getSource("name")} label="Nome" fullWidth />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <TextInput source={getSource("position")} label="Função" fullWidth />
                      </Grid>
                    </Grid>
                    <Grid container lg={8} md={12} spacing={2}>
                      <Grid item sx={12} md={4}>
                        <PhoneInput source={getSource("phone")} label="Telefone" fullWidth />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <TextInput source={getSource("email")} type="email" label="Email" validate={email()} fullWidth />
                      </Grid>
                    </Grid>
                  </>
                )}
              </FormDataConsumer>
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
        <FormTab label="Mapa de Pista">
          <ArrayInput source="qrCodes" label="">
            <SimpleFormIterator fullWidth disableReordering>
              <TextInput source="identifier" label="Identificador" disabled />
              <ArrayInput source="hoses" label="">
                <SimpleFormIterator fullWidth disableReordering inline>
                  <NumberInput source="code" label="Código" validate={[required(), minValue(1)]} />
                  <ReferenceInput
                    reference="fuels"
                    source="fuelId"
                    sort={{ field: "name", order: "ASC" }}
                  >
                    <SelectInput label="Combustível" optionText="name" validate={required()} isRequired />
                  </ReferenceInput>
                </SimpleFormIterator>
              </ArrayInput>
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
      </TabbedForm>
    </Create>
  )
};

const StationName = () => {
  const record = useRecordContext();
  return record ? <span>{record.name}</span> : <></>;
};

const QrcodePanel = ({ record }) => (
  <Datagrid data={record.hoses} bulkActionButtons={false}>
    <TextField source="hoseNumber" label="Bico" textAlign="center" />
    <TextField source="fuel.name" label="Combustível" textAlign="center" />
  </Datagrid>
);

const ListQrcodes = () => {
  const props = useShowContext();
  const headerCellStyle = { '& .RaDatagrid-headerCell': { backgroundColor: '#E5E5E5' } };

  return (
    <List
      title=" "
      resource="station-qrcodes"
      basePath="station-qrcodes"
      filter={{ numericId: props.record.numericId }}
      pagination={false}
      exporter={false}
      actions={false}
    >
      <Datagrid sx={headerCellStyle} expand={<QrcodePanel />} bulkActionButtons={false}>
        <TextField source="fuelPointNumber" label="Ponto de abastecimento" sortable={false} />
        <TextField source="qrcode" label="Qrcode" sortable={false} />
      </Datagrid>
    </List>
  );
};

const StationShow = props => {
  const { identity } = useGetIdentity();

  return (
    <Show
      {...props}
      title={<StationName />}
      hasEdit={identity && [UserRole.admin, UserRole.chain].includes(identity.role)}
    >
      <TabbedShowLayout>
        <Tab label="Identificação">
          <Permissions userRoles={[UserRole.admin]} label="Rede" >
            <ReferenceField reference="chains" source="chainId" >
              <TextField source="name" />
            </ReferenceField>
          </Permissions>
          <TextField disabled label="ID" source="id" />
          <NumberField source="numericId" label="ID Numérico" />
          <TextField source="name" label="Nome" />
          <TextField label="Email" source="email" type="email" />
          <FunctionField render={record => formatCnpj(record.cnpj)} label="CNPJ" />
          <FunctionField render={record => formatCellphone(record.phone)} label="Telefone" />
        </Tab>
        <Tab label="Endereço">
          <TextField source="street" label="Rua" />
          <TextField source="streetNumber" label="Número" />
          <TextField source="neighbourhood" label="Bairro" />
          <TextField source="city" label="Cidade" />
          <TextField source="state" label="Estado" />
          <NumberField source="cep" label="CEP" />
        </Tab>
        <Tab label="Dados bancários">
          <TextField source="accountName" label="Nome Completo" emptyText="--" />
          <FunctionField render={record => formatCnpj(record.accountCnpj)} label="CNPJ" />
          <TextField source="bankCode" label="Código do Banco" emptyText="--" />
          <TextField source="agency" label="Agência" emptyText="--" />
          <TextField source="accountNumber" label="Conta" emptyText="--" />
          <FunctionField render={record => BankAccountType.transalateType(record.accountType)} label="Tipo de conta" />
        </Tab>
        <Permissions permissions={[PERMISSIONS.ADMIN]}>
          <Tab label="QrCodes">
            <ListQrcodes />
          </Tab>
        </Permissions>
        <Tab label="Contatos">
          <ArrayField source="contacts" label="">
            <Datagrid bulkActionButtons={false}>
              <TextField source="name" label="Nome" />
              <TextField source="position" label="Função" />
              <TextField label="Email" source="email" type="email" />
              <FunctionField render={record => formatCellphone(record.phone)} label="Telefone" />
            </Datagrid>
          </ArrayField>
        </Tab>
        <Tab label="Mapa de Pista">
          <ArrayField source="qrCodes" label="">
            <Datagrid bulkActionButtons={false} expand={<Hoses />}>
              <TextField source="identifier" label="Qr Code" />
            </Datagrid>
          </ArrayField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

const Hoses = () => (
  <ArrayField source="hoses">
    <Datagrid bulkActionButtons={false}>
      <ReferenceField
        reference="fuels"
        source="fuelId"
        label="Combustível"
        link={false}
        sortable={false}
      >
        <TextField source="name" />
      </ReferenceField>
      <TextField source="code" label="código" sortable={false} />
    </Datagrid>
  </ArrayField>
);

const resource = {
  list: StationList,
  edit: new ComponentWithPermissions({ component: StationEdit, permissions: [PERMISSIONS.EDIT_STATIONS] }),
  create: new ComponentWithPermissions({ component: StationCreate, permissions: [PERMISSIONS.ADMIN] }),
  show: StationShow,
  name: 'stations',
  permissions: [PERMISSIONS.LIST_STATIONS]
};

export default resource;
