import React, { useState } from 'react';
import {
  TextInput,
  SimpleForm,
  Toolbar,
  required,
  minLength,
  maxLength,
  useDataProvider,
  useNotify,
  List,
  Datagrid,
  TextField,
  FunctionField,
  Create,
  TopToolbar,
  CreateButton,
  Button,
  FormDataConsumer,
  useRedirect,
  Show,
  TabbedShowLayout,
  Tab,
  NumberField,
  ArrayField,
  useRecordContext,
} from 'react-admin';

import styles from './Company.module.scss';
import { formatCellphone, formatCep, formatCnpj, getChainId } from '../../utils/utils';
import { Search as SearchIcon, Save as SaveIcon } from '@mui/icons-material';
import { Box, CircularProgress, Grid } from '@mui/material';
import UnlinkCompanyButton from './unlinkCompanyButton';
import { PERMISSIONS } from '../../constants';

const validate = [required(), minLength(4, "Precisa ter 4 dígitos"), maxLength(4, "Precisa ter 4 dígitos")];

const CustomToolbar = props => (
  <Toolbar {...props}>
    <Button
      type="submit"
      label="Adicionar"
      variant="contained"
      size="medium"
      disabled={props.disabledButton || props.invalid || props.isLoading}
      startIcon={props.isLoading ? <CircularProgress size={20} /> : <SaveIcon />}
    />
  </Toolbar>
);

const LinkedCompanyCreate = (props) => {
  const [company, setCompany] = useState(null);
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const redirect = useRedirect();
  const [isLoading, setIsLoading] = useState(false);

  const executeSearch = ({ code }) => {
    if (code?.length !== 4) {
      return null;
    }
    setCompany(null);

    dataProvider.getOne(`companies/code`, { id: code })
      .then(({ data }) => {
        // @ts-ignore
        setCompany(data);
      })
      .catch(({ message }) => { notify(message, { type: 'warning' }); });
  };

  const addCompany = id => {
    setIsLoading(true);
    const chainId = getChainId();

    if (chainId) {
      dataProvider.getOne('chains', { id: chainId }).then(({ data }) => {
        if (data.companyIds.includes(id)) {
          notify('Transportadora já adicionada!')
          setIsLoading(false);
          return null;
        }

        dataProvider.update('chains', {
          id: chainId,
          data: {
            companyIds: data.companyIds.concat([id])
          },
          previousData: data
        })
          .then(() => {
            notify('Transportadora adicionada!');
            redirect('/linked-companies');
          })
          .catch(error => { notify(error && error.message, { type: 'warning' }) })
          .finally(() => { setIsLoading(false); });

      })
        .catch(error => {
          notify(error && error.message, { type: 'warning' });
          setIsLoading(false);
        });
    }
  };

  return (
    <Create {...props} title="Adicionar transportadora">
      <SimpleForm
        toolbar={<CustomToolbar disabledButton={!company} isLoading={isLoading} />}
        onSubmit={() => addCompany(company.id)}
        mode="onTouched"
      >
        <div style={{ display: 'flex', gap: 10 }}>
          <FormDataConsumer>
            {({ formData }) => {
              return (
                <Grid container spacing={2} sx={{ alignItems: 'center' }}>
                  <Grid item>
                    <TextInput
                      source="code"
                      resettable={true}
                      label="Identificador de Transportadora"
                      validate={validate}
                      parse={v => v && v.toUpperCase().replace(/[^A-Z]/g, '').slice(0,4)}
                    />
                  </Grid>
                  <Grid item>
                    <Button
                      label="Buscar"
                      onClick={() => executeSearch(formData)}
                      variant="outlined"
                      size="medium"
                      startIcon={<SearchIcon />}
                      sx={{ marginBottom: 2 }}
                    />
                  </Grid>
                </Grid>
              )
            }}
          </FormDataConsumer>
        </div>
        {company && (
          <div className={styles.container}>
            <div className={styles.couponInfo}>
              <div>
                <span className={styles.info}>
                  <b>Nome: </b>
                  {company.name}
                </span>
                <span className={styles.info}>
                  <b>CNPJ: </b>
                  {formatCnpj(company.cnpj)}
                </span>
                <span className={styles.info}>
                  <b>Telefone: </b>
                  {formatCellphone(company.phone)}
                </span>
              </div>
              <div style={{ marginTop: 15 }}>
                <span className={styles.info}>
                  <b>Endereço: </b>
                  {company.street}, {company.streetNumber}
                </span>
                <span className={styles.info}>
                  <b>Cidade: </b>
                  {company.city}
                </span>
                <span className={styles.info}>
                  <b>Cep: </b>
                  {formatCep(company.cep)}
                </span>
              </div>
            </div>
          </div>
        )}
      </SimpleForm>
    </Create>
  );
}

const ListActions = props => {
  return (
    <TopToolbar>
      <CreateButton resource="linked-companies" label="Adicionar" />
    </TopToolbar>
  );
};

const LinkedCompanyList = (props) => (
  <List
    {...props}
    resource="companies"
    title="Transportadoras"
    empty={false}
    filter={{ chainId: getChainId() }}
    actions={<ListActions />}
  >
    <Datagrid rowClick={(id) => `/linked-companies/${id}/show`} bulkActionButtons={false}>
      <TextField source="name" label="Transportadora" />
      <FunctionField render={record => formatCnpj(record.cnpj)} label="CNPJ" />
      <FunctionField render={record => formatCellphone(record.phone)} label="Telefone" sortable={false} />
      <UnlinkCompanyButton />
    </Datagrid>
  </List>
);

const CompanyName = () => {
  const record = useRecordContext();
  return <span>{record && record.name}</span>;
};

const LinkedCompanyShow = () => (
  <Show resource="companies" title={<CompanyName />}>
    <TabbedShowLayout>
      <Tab label="Identificação">
        <TextField source="code" label="Código" />
        <TextField source="name" label="Nome" />
        <FunctionField render={record => formatCnpj(record.cnpj)} label="CNPJ" />
        <FunctionField render={record => formatCellphone(record.phone)} label="Telefone" />
      </Tab>
      <Tab label="Endereço">
        <TextField source="street" label="Rua" />
        <TextField source="streetNumber" label="Número" />
        <TextField source="neighbourhood" label="Bairro" />
        <TextField source="city" label="Cidade" />
        <TextField source="state" label="Estado" />
        <NumberField source="cep" label="CEP" />
      </Tab>
      <Tab label="Contatos">
        <ArrayField source="contacts" label="">
          <Datagrid bulkActionButtons={false}>
            <TextField source="name" label="Nome" />
            <TextField source="position" label="Função" />
            <TextField label="Email" source="email" type="email" />
            <FunctionField render={record => formatCellphone(record.phone)} label="Telefone" />
          </Datagrid>
        </ArrayField>
      </Tab>
    </TabbedShowLayout>
  </Show>
);

const resource = {
  list: LinkedCompanyList,
  create: LinkedCompanyCreate,
  show: LinkedCompanyShow,
  name: 'linked-companies',
  permissions: [PERMISSIONS.LIST_COMPANIES]
};

export default resource;