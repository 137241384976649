import jsonExport from 'jsonexport/dist';
import React from 'react';
import {
  ArrayField,
  Datagrid,
  FunctionField,
  List,
  ReferenceField,
  ReferenceOneField,
  Show,
  SimpleShowLayout,
  Tab,
  TabbedShowLayout,
  TextField,
  downloadCSV,
  useResourceContext,
} from 'react-admin';
import { Permission } from '../../components';
import { UserRole } from '../../providers/authProvider';
import { formatCellphone, getCompanyId } from '../../utils/utils';
import { PERMISSIONS } from '../../constants';
import SwitchPreApprovedContracts from './SwitchPreApprovedContracts';

const LinkedChains: React.FC = (props) => {

  const resource = useResourceContext();

  const chainsExporter = async (data: any) => {

    const chains = data.map((item: any) => ({
      rede: item['name'],
      telefone: item['phone'],
      nomeDeContato: item['contactName'],
    }));

    jsonExport(chains, { rowDelimiter: ';' }, (err, csv) => {
      downloadCSV(csv, 'redes_vinculadas');
    });
  }

  return (
    <Permission userRole={UserRole.company} print>
      <List
        {...props}
        resource="chains"
        title="Redes"
        filter={{ companyId: getCompanyId() }}
        sort={{ field: "name", order: "ASC" }}
        exporter={chainsExporter}
        hasCreate={false}
      >
        <Datagrid bulkActionButtons={false} rowClick={(id: any) => `/${resource}/${id || ''}/show`}>
          <TextField source="name" label="Rede" />
          <FunctionField render={(record: any) => formatCellphone(record.phone)} label="Telefone" sortable={false} />
          <TextField source="contactName" label="Nome do contato" />
        </Datagrid>
      </List>
    </Permission>
  );
}

const ShowLinkedChains: React.FC = (props) => {
  return (
    <Permission userRole={UserRole.company}>
      <Show
        {...props}
        title="Rede"
      >
        <TabbedShowLayout>
          <Tab label="Geral">
            <ReferenceField source="chainId" reference="chains" label="Nome">
              <TextField source="name" />
            </ReferenceField>
            <ReferenceField source="chainId" reference="chains" label="Nome de contato">
              <TextField source="contactName" emptyText="--" />
            </ReferenceField>
            <ReferenceField source="chainId" reference="chains" label="Telefone">
              <FunctionField render={(record: any) => record && record.phone ? formatCellphone(record.phone) : '--'} />
            </ReferenceField>
            <SwitchPreApprovedContracts source="preApprovedContracts" label="Contratos" />
          </Tab>
          <Tab label="Contatos">
            <ReferenceOneField label="" reference="chains" target="id">
              <ArrayField source="contacts" label="">
                <Datagrid bulkActionButtons={false}>
                  <TextField source="name" label="Nome" />
                  <TextField source="position" label="Função" />
                  <TextField label="Email" source="email" type="email" />
                  <FunctionField render={record => formatCellphone(record.phone)} label="Telefone" />
                </Datagrid>
              </ArrayField>
            </ReferenceOneField>
          </Tab>
        </TabbedShowLayout>
      </Show>
    </Permission>
  );
}

const resource = {
  list: LinkedChains,
  show: ShowLinkedChains,
  name: 'linked-chains',
  permissions: [PERMISSIONS.LIST_CHAINS]
};

export default resource;