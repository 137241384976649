import React from 'react'
import {
  List,
  Filter,
  Datagrid,
  TextField,
  ReferenceField,
  DateField,
  NumberField,
  DateInput,
  DateTimeInput,
  ReferenceInput,
  SelectInput,
} from 'react-admin'
import ListPagination from '../../components/ListPagination'
import Permission from '../../components/Permission'
import { PERMISSIONS } from '../../constants'
import dayjs from 'dayjs'

const ListFilter = ({ ...props }) => (
  <Filter {...props}>
    <DateTimeInput source="from" label="De" alwaysOn />
    <DateTimeInput source="to" label="Até" alwaysOn />
    <ReferenceInput source="chainId" reference="chains" alwaysOn>
      <SelectInput label="Redes" optionText="name" optionValue="id" />
    </ReferenceInput>
  </Filter>
)

const FillinContractPriceAlertList: React.FC<{}> = (props) => (
  <Permission permission={PERMISSIONS.ADMIN}>
    <List
      {...props}
      perPage={25}
      pagination={<ListPagination />}
      exporter={false}
      title="Alerta de preço nos abastecimento com contrato"
      sort={{ field: 'createdAt', order: 'DESC' }}
      filters={<ListFilter {...props} />}
      filterDefaultValues={{
        from: dayjs().subtract(1, 'month').startOf('day').toISOString(),
        to: dayjs().endOf('day').toISOString(),
      }}
      disableSyncWithLocation
    >
      <Datagrid bulkActionButtons={false} rowClick={(id, resource, record) => {
        return `/fillins/${record.fillinId}/show`;
      }}>
        <ReferenceField source="fillinId" reference="fillins" label="Contrato" emptyText="--" textAlign="center" link="show" sortable={false}>
          <ReferenceField source="contractId" reference="contracts" label="" emptyText="--" link="show">
            <TextField source="code" />
          </ReferenceField>
        </ReferenceField>
        <TextField source="reserveId" label="Reserva" />
        <NumberField
          source="contractPricePerLiter"
          label="Preço do acordo"
          locales="pt-BR"
          textAlign="center"
          emptyText="R$ 0,000"
          options={{
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 3,
            maximumFractionDigits: 3
          }}
        />
        <NumberField
          source="pricePerLiter"
          label="Preço do abastecimento"
          locales="pt-BR"
          textAlign="center"
          emptyText="R$ 0,000"
          options={{
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 3,
            maximumFractionDigits: 3
          }}
        />
        <DateField source="createdAt" label="Data" textAlign="center" showTime />
      </Datagrid>
    </List>
  </Permission>
);

export default FillinContractPriceAlertList;