import { Datagrid, DateField, DateInput, Filter, NumberField, ReferenceField, SearchInput, TextField, Button, useNotify, useRecordContext, DeleteButton, ReferenceManyField, ChipField, SingleFieldList, ReferenceInput, SelectInput, useListContext, ReferenceArrayInput, SelectArrayInput, ReferenceOneField, useGetIdentity } from "react-admin";
import { List, Permissions } from "../../components";
import { saveAs } from 'file-saver';
import moment from "moment";
import { PERMISSIONS } from "../../constants";
import { UserRole } from "../../providers/authProvider";
import { getCompanyId } from "../../utils/utils";

const FilterList = (props: any) => {
  const { filterValues } = useListContext();
  const { identity } = useGetIdentity();

  return (
    <Filter {...props}>
      <DateInput source="from" label="De" alwaysOn sx={{ marginBottom: 0.5 }} />
      <DateInput source="to" label="Até" alwaysOn sx={{ marginBottom: 0.5 }} />
      {
        Permissions({
          permissions: [PERMISSIONS.LIST_CHAINS],
          userRoles: [UserRole.admin, UserRole.company],
          children: (
            <ReferenceInput
              source="chainId"
              reference="chains"
              sort={{ field: "name", order: "ASC" }}
              filter={{ companyId: getCompanyId(), onlyWithContract: true, }}
              emptyText="Todos"
              alwaysOn
            >
              <SelectInput
                emptyText="Todos"
                label="Rede"
                optionText={'name'}
              />
            </ReferenceInput>
          )
        })
      }
      <ReferenceArrayInput
        reference="stations"
        source="stationId"
        filter={filterValues.chainId ? { chainId: filterValues.chainId } : {}}
        alwaysOn
      >
        <SelectArrayInput
          label="Posto"
          optionText={'name'}
          disabled={([UserRole.admin, UserRole.company].includes(identity?.role) && !filterValues.chainId)}
        />
      </ReferenceArrayInput>
      <SearchInput source="search" alwaysOn />
    </Filter>
  );
}

const DownloadFile = () => {
  const record = useRecordContext();
  const notify = useNotify();

  if (!record) { return null; }

  const handleDownloadXml = (url: string) => {
    try {
      saveAs(url, url.split('/').slice(-1));
    } catch (error) {
      notify(error.message || `Erro ao baixar XML da nota fiscal`, { type: 'warning' });
    }
  }

  return (
    <Button
      label="Baixar XML"
      onClick={(e) => {
        e.stopPropagation();
        handleDownloadXml(record.path);
      }}
    />
  );
};

const InvoiceList = () => {
  return (
    <Permissions permissions={[PERMISSIONS.LIST_INVOICE]}>
      <List
        title="Notas Fiscais"
        filters={<FilterList />}
        sort={{ field: "createdAt", order: "DESC" }}
        filterDefaultValues={{
          from: moment().startOf('day').subtract(1, 'month').toISOString(),
          to: moment().endOf('day').toISOString()
        }}
        exporter={false}
        perPage={25}
        actions={false}
      >
        <Datagrid bulkActionButtons={false} rowClick="show">
          <TextField source="invoiceNumber" label="Número" />
          <NumberField source="totalInvoiceValue" locales="pt-br" label="Valor da nota" textAlign="left" options={{ style: 'currency', currency: 'BRL', minimumFractionDigits: 2, maximumFractionDigits: 2 }} />
          <TextField source="serie" label="Série" emptyText="Não identificado" />
          <DateField source="issueDate" locales="pt-BR" label="Data de emissão" emptyText="Não identificado" showTime />
          <TextField source="accessKey" label="Chave de acesso" emptyText="Não identificado" />
          {/* <ReferenceField source="billId" reference="bills" label="Fatura" link="show" emptyText="--">
            <TextField source="numericId" />
          </ReferenceField> */}
          {
            Permissions({
              permissions: [PERMISSIONS.LIST_CHAINS],
              userRoles: [UserRole.admin, UserRole.company],
              children: (
                <ReferenceField label="Rede" reference="chains" source="chainId">
                  <TextField source="name" />
                </ReferenceField>
              )
            })
          }
          <ReferenceOneField
            label="Posto"
            reference="fillins"
            target="invoiceId"
          >
            <ReferenceField label="Posto" reference="stations" source="stationId" link={false}>
              <TextField source="name" />
            </ReferenceField>
          </ReferenceOneField>
          <ReferenceManyField
            label="Abastecimento"
            reference="fillins"
            target="invoiceId"
            sort={{ field: 'createdAt', order: 'DESC' }}
          >
            <SingleFieldList linkType="show">
              <ChipField source="numericId" />
            </SingleFieldList>
          </ReferenceManyField>
          <DateField source="createdAt" label="Data do upload" showTime />
          <DownloadFile />
          <Permissions userRoles={[UserRole.company, UserRole.admin]} permissions={[PERMISSIONS.DELETE_INVOICE]}>
            <DeleteButton />
          </Permissions>
        </Datagrid>
      </List>
    </Permissions>
  );
};

export default InvoiceList;